import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { LiaSortUpSolid } from "react-icons/lia";
import { FiSearch } from "react-icons/fi";

import { FaPlus } from "react-icons/fa6";

const ReactDataTable = ({
  useMinimumHeight = true,
  loading = false,
  columns = [],
  dataSource = [],
  notFoundLabel = "No Data Available",
  searchInputLabel = "Search...",
  filteredFunction,
  addButtonLabel = "Add",
  addButtonFunction,
}) => {
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilterText("");
    setFilteredData(dataSource);
  }, [dataSource]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setFilterText(query);

    if (query === "") {
      setFilteredData(dataSource);
    } else {
      const filtered = filteredFunction(filteredData, query);
      setFilteredData(filtered);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "700",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
    },
    rows: {
      style: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
    },
    table: {
      style: {
        minHeight: useMinimumHeight ? "75vh" : "auto", // Minimum table height
      },
    },
  };

  const NoDataComponent = () => (
    <div className="text-center p-5">
      <h2>{notFoundLabel}</h2>
    </div>
  );

  const CustomLoader = () => (
    <div className="text-center p-5">
      <h2>Loading...</h2>
    </div>
  );

  return (
    <>
      <div className="flex justify-between items-center">
        {filteredFunction && (
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <FiSearch />
            </div>
            <input
              type="search"
              id="default-search"
              class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:border-[#0978FE]"
              placeholder={searchInputLabel}
              value={filterText}
              onChange={handleSearch}
            />
          </div>
        )}
        {addButtonFunction && (
          <button
            type="submit"
            className={`flex items-center justify-center gap-2 rounded-lg capitalize bg-white hover:bg-[#F0F5FF] border border-gray-300 text-sm font-semibold
              outline-none transition active:focus:scale-95 w-1/6 h-10 hover:border-[#0978FE]`}
            onClick={addButtonFunction}
          >
            <FaPlus />
            {addButtonLabel}
          </button>
        )}
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        progressPending={loading}
        progressComponent={<CustomLoader />}
        pagination
        customStyles={customStyles}
        noDataComponent={<NoDataComponent />}
        sortIcon={
          <LiaSortUpSolid
            style={{
              width: "12px",
              height: "12px",
              margin: "3px",
            }}
          />
        }
      />
    </>
  );
};

export default ReactDataTable;
