import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa6";

import {
  getBuildingList,
  upsertBuilding,
  deleteBuilding,
} from "../../services/api.service";
import { useAppContext } from "../../context/AppContextProvider";

import ConfirmDialog from "../../components/ConfirmDialog";
import TableActionButton from "../../components/TableActionButton";
import ReactDataTable from "../../components/ReactDataTable";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";

const ManageBuilding = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const [openForm, setOpenForm] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [buildingList, setBuildingList] = useState([]);
  const [buildingDetail, setBuildingDetail] = useState({});
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const buildingColumns = [
    { name: "Building ID", selector: (item) => item.id, sortable: true },
    { name: "Nama", selector: (item) => item.name, sortable: true },
    {
      name: "",
      button: true,
      cell: (item) => (
        <TableActionButton
          hiddenView
          hiddenPrintBarcode
          onEdit={() => {
            setOpenForm(true);
            setBuildingDetail(item);
            onChange("name", item?.name);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setBuildingDetail(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetBuildingList();
  }, []);

  const onGetBuildingList = async () => {
    try {
      setFetchLoading(true);
      const response = await getBuildingList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setBuildingList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListBuilding: ", error);
    }
  };

  const onUpsertBuilding = async (data) => {
    if (deleteLoading) return;
    try {
      const request = {
        id: buildingDetail?.id,
        name: data?.name || buildingDetail?.name,
      };

      setUpdateLoading(true);
      const response = await upsertBuilding(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenForm(false);
      setValue("days", null);
      onGetBuildingList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpsertBuilding: ", error);
    }
  };

  const onOpenForm = () => {
    if (buildingList?.length >= userDetail?.config?.max_building) {
      onOpenAlert("error", "Max Building limit reached");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setBuildingDetail({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteBuilding(buildingDetail?.id);
      if (!response.success) throw response.message;

      setDeleteLoading(false);
      setOpenConfirm(false);
      onGetBuildingList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  const filteredFunction = (data, input) =>
    data.filter((item) => item.name.toString().includes(input));

  return (
    <>
      <div className="bg-white p-4 m-6 rounded-lg">
        <ReactDataTable
          columns={buildingColumns}
          dataSource={buildingList}
          loading={fetchLoading}
          filteredFunction={filteredFunction}
          addButtonFunction={onOpenForm}
        />
      </div>
      <Modal
        open={openForm}
        onOpen={setOpenForm}
        className={{ container: "p-0" }}
      >
        <form
          onSubmit={handleSubmit(onUpsertBuilding)}
          className="overflow-auto"
        >
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              <FaPen />
            </div>
            {buildingDetail?.id ? (
              <>
                <div className="ml-3 mr-1 text-lg">Edit</div>
                <div className="flex items-center">
                  <h1 className="mx-1 text-sm">({buildingDetail?.name})</h1>
                </div>
              </>
            ) : (
              <div className="ml-3 mr-1 text-lg">Add</div>
            )}
          </div>
          <div className="px-8 py-6 flex flex-col gap-2">
            <div className="mb-5 gap-x-3">
              <Input
                label="Name"
                placeholder="Input Nama Gedung"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.name?.message}
                onInputChange={(e) => onChange("name", e.target.value)}
                {...register("name")}
              />
            </div>
            <Button
              label="submit"
              isLoading={updateLoading}
              className="w-1/3 h-10 mt-8 ml-auto"
            />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={deleteLoading}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default ManageBuilding;
