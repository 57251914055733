import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCameraOutdoor } from "react-icons/md";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

import { useAppContext } from "../../context/AppContextProvider";
import { getAnalytics, getReportMRM } from "../../services/api.service";
import CardCount from "../../components/CardCount";
import Select from "../../components/Select";
import Button from "../../components/Button";
import ReactDataTable from "../../components/ReactDataTable";

const monthsOptions = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const DashboardMrm = () => {
  const navigate = useNavigate();
  const { userDetail, key } = useAppContext();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchReportLoading, setFetchReportLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [startMonth, setStartMonth] = useState(new Date().getMonth());
  const [endMonth, setEndMonth] = useState(new Date().getMonth() + 1);
  const [reports, setReports] = useState([]);
  const [keyChart, setKeyChart] = useState(null);

  const isSuperAdmin = userDetail?.levelName === "superadmin";

  useEffect(() => {
    onGetAnalytics();
  }, []);

  useEffect(() => {
    setKeyChart(key);
  }, [key]);

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics("module-mrm");
      if (!response.success) throw response.message;

      setFetchLoading(false);

      setAnalytics(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  };

  if (isSuperAdmin) {
    return navigate("/home");
  }

  const options = {
    chart: {
      type: "spline",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: analytics.charts?.data.length ? undefined : "No Data Available",
    },
    xAxis: {
      categories: analytics?.charts?.labels,
      labels: {
        formatter: function () {
          return moment(this.value).format("DD-MMM-YYYY");
        },
      },
    },
    yAxis: {
      title: {
        text: "Booking Ruang",
      },
    },
    tooltip: {
      headerFormat: null,
    },
    series: [
      {
        name: "Booking Ruang",
        // data: [525, 1134, 385, 102, 684, 1296, 1195, 666, 1382, 942, 1145, 467, 1351, 224, 1459, 1196, 688, 1084, 1466, 808, 994, 347, 1371, 420, 1131, 228, 713, 539, 663, 1238, 663],
        data: analytics.charts?.data || [],
        color: "rgb(37, 99, 235, .8)",
      },
    ],
  };

  const columReports = [
    { key: "No", selector: (val, index) => index + 1 },
    { name: "Ruangan", selector: (item) => item.name, sortable: true },
    { name: "Event", selector: (item) => item.title, sortable: true },
    {
      key: "Start Event",
      selector: (item) => item.start_date,
      sortable: true,
      format: (item) => moment(item.start_date).format("DD-MMM-YYYY HH:mm"),
    },
    {
      key: "End Event",
      selector: (item) => item.start_date,
      sortable: true,
      format: (item) => moment(item.start_date).format("DD-MMM-YYYY HH:mm"),
    },
    {
      name: "Employee",
      selector: (item) => item.employee.name,
      sortable: true,
    },
  ];

  const fetchReport = async () => {
    try {
      const timeNow = moment();
      const request = {
        startDate: timeNow
          .set("month", startMonth - 1)
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: timeNow
          .set("month", endMonth - 1)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };

      setFetchReportLoading(true);
      const response = await getReportMRM(request);
      if (!response.success) throw response.message;

      setFetchReportLoading(false);
      setReports(response?.data);
    } catch (error) {
      setFetchReportLoading(false);
      console.log("Error on fetchReport: ", error);
    }
  };

  return (
    <div className="p-6">
      <div className="flex gap-6 mb-6">
        <CardCount
          title="Booking Room"
          value={analytics?.totalMeetingRoomsInMonth || 0}
          icon={<MdOutlineCameraOutdoor />}
          isLoading={fetchLoading}
          className={{
            icon: "text-[#5B93FF] bg-[#5B93FF]/20",
          }}
        />
      </div>
      <div className="bg-white p-1 rounded-lg">
        <HighchartsReact
          key={keyChart}
          highcharts={Highcharts}
          options={options}
        />
      </div>
      <div className="mt-6 bg-white p-4 rounded-lg">
        <span className="text-lg font-bold">Table Report</span>

        <div className="mt-3 flex justify-between items-center">
          <div className="flex gap-1">
            <Select
              label="Start"
              placeholder="Pilih Bulan"
              options={monthsOptions}
              defaultValue={monthsOptions[startMonth - 1]}
              className={{
                container: "w-40",
                input: "h-10 pl-3 capitalize",
                suffix: "text-sky-700",
                option: "capitalize",
              }}
              onInputChange={(e) => setStartMonth(e.value)}
            />
            <Select
              label="End"
              placeholder="Pilih Bulan"
              options={monthsOptions}
              defaultValue={monthsOptions[endMonth - 1]}
              className={{
                container: "w-40",
                input: "h-10 pl-3 capitalize",
                suffix: "text-sky-700",
                option: "capitalize",
              }}
              onInputChange={(e) => setEndMonth(e.value)}
            />
          </div>
          <Button
            className="w-1/6 h-10"
            label="Generate"
            onClickBtn={fetchReport}
          />
        </div>
        <ReactDataTable
          useMinimumHeight={false}
          columns={columReports}
          dataSource={reports}
          loading={fetchReportLoading}
          notFoundLabel={`Data tidak tampil dari bulan ${moment()
            .subtract(1, "months")
            .format("MMMM")} sd ${moment().format("MMMM")}`}
        />
      </div>
    </div>
  );
};

export default DashboardMrm;
