import { Navigate, useSearchParams } from "react-router-dom";
import { destroyToken, getLocalStorage, getLocalToken, getSSOToken, removeLocalStorage } from "../services/jwt.service";
import { periodLicenseValidator } from "../utils/helper";

const AuthenticateRoute = ({ redirectPath = "/login", children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const userDetail = getLocalStorage("user");
  const ssoToken = getSSOToken();
  const localToken = getLocalToken();

  const roomID = searchParams.get('room');

  const redirectPathWithQuery = roomID ? `${redirectPath}?room=${roomID}` : redirectPath;

  if (!ssoToken && !localToken) return <Navigate to={redirectPathWithQuery} replace />;

  // period license validation routes
  if (userDetail?.levelName === "owner") {
    if (!userDetail?.periodLicense) {
      return <Navigate to={redirectPath} replace />
    }

    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);

    if (rangeOfDay <= 0) {
      destroyToken();
      removeLocalStorage("user");
      return <Navigate to={redirectPath} replace />
    };
  }

  return children;
};

export default AuthenticateRoute;