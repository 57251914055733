import React from 'react'
import { useSearchParams } from 'react-router-dom';

import { useAppContext } from "../../context/AppContextProvider";
import MyCalendar from "../../components/MyCalendar";

const EmployeeCalendar = () => {
    const { userDetail, onOpenAlert } = useAppContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const roomID = searchParams.get('room');


    return (
        <div className='bg-white w-full h-full p-5 rounded-lg'>
            <MyCalendar userDetail={userDetail} onOpenAlert={onOpenAlert} roomID={roomID}/>
        </div>
    )
}


export default EmployeeCalendar;