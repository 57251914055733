import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { initializeAxios } from "../services/api.service";
// Import Middleware
import AuthenticateRoute from "./AuthenticateRoute";

// Import Authentication
import Login from "../pages/authenticate/Login";
import Register from "../pages/authenticate/Register";
import ForgotPassword from "../pages/authenticate/ForgotPassword";

// import Layout
import Main from "../layout/Main";
import Home from "../layout/LayoutHome";

// Import Module Super Admin
import Dashboard from "../pages/moduleAdmin/Dashboard";

import ManageEmployee from "../pages/moduleEmployee/ManageEmployee";
import ManageOwner from "../pages/moduleAdmin/ManageOwner";
import Directory from "../pages/home/Directory";

// Import Module Employee
import DashboardEmployee from "../pages/moduleEmployee/Dashboard";
import StaffForm from "../pages/moduleEmployee/EmployeeForm";

// Import Module MRM
import DashboardMrm from "../pages/moduleMRM/Dashboard";
import EmployeeCalendar from "../pages/moduleMRM/EmployeeCalendar";

// Import Module Facility
import DashboardFacility from "../pages/moduleFacility/Dashboard";
import ManageBuilding from "../pages/moduleFacility/ManageBuilding";
import ManageFloor from "../pages/moduleFacility/ManageFloor";
import ManageRooms from "../pages/moduleFacility/ManageRoom";

const RoutesManager = () => {
  initializeAxios();

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* Router Module Super Admin */}
        <Route
          path="/super-admin"
          element={
            <AuthenticateRoute>
              <Main />
            </AuthenticateRoute>
          }
        >
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="owner" element={<Outlet />}>
            <Route index element={<ManageOwner />} />
            <Route path="form" element={<StaffForm />} />
            <Route path="form/:staffId" element={<StaffForm />} />
          </Route>
        </Route>

        {/* Router Module Owner */}
        <Route
          path="/owner"
          element={
            <AuthenticateRoute>
              <Main />
            </AuthenticateRoute>
          }
        >
          {/* Router Module Employee */}
          <Route path="module-employee" element={<Outlet />}>
            <Route index element={<DashboardEmployee />} />
            <Route path="employee" element={<ManageEmployee />} />
            <Route path="employee-form" element={<StaffForm />} />
            <Route path="employee-form/:employeeId" element={<StaffForm />} />
          </Route>

          {/* Router Module Facility */}
          <Route path="module-facility" element={<Outlet />}>
            <Route index element={<DashboardFacility />} />
            <Route path="buildings" element={<ManageBuilding />} />
            <Route path="floors" element={<ManageFloor />} />
            <Route path="rooms" element={<ManageRooms />} />
          </Route>

          {/* Router Module Meeting Room Management System */}
          <Route path="module-mrm" element={<Outlet />}>
            <Route index element={<DashboardMrm />} />
            <Route path="calendar" element={<EmployeeCalendar />} />
          </Route>
        </Route>

        {/* Router Module Employee */}
        <Route
          path="/employee"
          element={
            <AuthenticateRoute>
              <Home />
            </AuthenticateRoute>
          }
        >
          <Route path="module-mrm" element={<Outlet />}>
            <Route index element={<DashboardMrm />} />
          </Route>
        </Route>

        <Route
          path="/"
          element={
            <AuthenticateRoute>
              <Home />
            </AuthenticateRoute>
          }
        >
          <Route path="home" index element={<Directory />} />
          <Route path="calendar" element={<EmployeeCalendar />} />
        </Route>

        {/* Router Public */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-reset/:token" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="login" replace />} />
      </Route>
    </Routes>
  );
};

export default RoutesManager;
