import { Link } from "react-router-dom";
import { FaUsersViewfinder } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";

import { useAppContext } from "../../context/AppContextProvider";
import { WavingHand } from "../../assets/icon/SvgIconComponents";
import { MdOutlineCameraOutdoor } from "react-icons/md";
import { BsBuildings } from "react-icons/bs";

const Directory = () => {
  const { userDetail, setActiveModule } = useAppContext();
  const isOwner = userDetail?.levelName === "owner";
  const isEmployee = userDetail?.levelName === "employee";
  const isReceptionist = userDetail?.levelName === "receptionist";
  const modules = userDetail?.modules || [];

  const employeeModule = modules.filter((m) => [3].includes(m.module_id));

  const icons = {
    employee: <FaUsersViewfinder />,
    facility: <BsBuildings />,
    "meeting room management system": <MdOutlineCameraOutdoor />,
    calendar: <IoCalendarOutline />,
  };

  const descriptions = {
    employee: "Manage employee Data, Profiles, Data, and Self-Service Access",
    facility: "Manage Facility, Asset and Space Operation",
    "meeting room management system": "Manage Meeting Room to Optimize Resource Operation",
    calendar: "Quickly book meeting rooms with just a few taps",
  }

  return (
    <>
      <div className="flex gap-2 items-center text-[#0978FE]">
        <WavingHand />
        <h1 className="font-semibold text-base">
          Hey <span className="capitalize">{userDetail?.name}</span>!
        </h1>
      </div>
      <h2 className="font-bold text-lg mt-4 mb-9">
        {isOwner
          ? "You're all set to manage your office."
          : "Manage your office activities easily and efficiently."}
      </h2>
      <div className="flex flex-wrap gap-8">
        {isOwner &&
          modules.map((m, index) => (
            <Link
              to={`/owner${m.module.url}`}
              onClick={() => setActiveModule(m.module.name)}
              key={`card-module-${index}`}
              className="lg:w-[276px] w-full bg-[#FFFFFF] border border-[#E8E5E5] rounded-md hover:bg-[#F0F5FF] hover:border-[#0978FE]"
            >
              <div className="flex flex-row gap-3 p-6">
                <div className="flex flex-col gap-3">
                  {icons[m.module.name] && (
                    <div className="text-xl text-[#0978FE]">
                      {icons[m.module.name]}
                    </div>
                  )}
                  <span className="text-base font-bold">
                    {m.module.display}
                  </span>
                  <span className="text-xs font-medium">{descriptions[m.module.name]}</span>
                </div>
              </div>
            </Link>
          ))}

        {isOwner && !modules.length && (
          <span>Please Contact super admin to add the modules</span>
        )}

        {isReceptionist && (
          <span className="bg-red-400 text-slate-100 p-3 rounded font-light text-lg w-full">
            Features for receptionists in development
          </span>
        )}

        {isEmployee &&
          employeeModule.find(
            (m) => m.module.name === "meeting room management system"
          ) && (
            <Link
              to="/calendar"
              onClick={() => setActiveModule("employee-calendar")}
              className="lg:w-[276px] w-full bg-[#FFFFFF] border border-[#E8E5E5] rounded-md hover:bg-[#F0F5FF] hover:border-[#0978FE]"
            >
              <div className="flex flex-row gap-3 p-6">
                <div className="flex flex-col gap-3">
                  {icons["calendar"] && (
                    <div className="text-xl text-[#0978FE]">
                      {icons["calendar"]}
                    </div>
                  )}
                  <span className="text-base font-bold">Room Booking</span>
                  <span className="text-xs font-medium">{descriptions["calendar"]}</span>
                </div>
              </div>
            </Link>
          )}
      </div>
    </>
  );
};

export default Directory;
