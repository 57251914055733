import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";
import { cn } from "../utils/helper";

function InputGroup({
  label,
  name,
  value,
  onChange,
  type = "text",
  decoration,
  inputClassName = "",
  decorationClassName = "",
  disabled
}) {
  return (
    <div className="flex flex-row items-stretch w-full">
      <input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={label}
        aria-label={label}
        className={cn(
          "peer block w-full py-2.5 pl-4 pr-8 text-sm text-gray-500 placeholder-gray-400 border border-r-0 focus:border-[#7D818B] focus:outline-none focus:ring-0 appearance-none rounded-tr-none rounded-br-none rounded transition-colors duration-300",
          { "bg-gray-200": disabled },
          inputClassName
        )}
        disabled={disabled}
      />
      <div
        className={cn(
          "flex items-center rounded-tl-none rounded-bl-none rounded  text-sm text-gray-500 pr-3 py-3 border border-l-0 peer-focus:border-[#7D818B] peer-focus:bg-white transition-colors duration-300",
          { "bg-gray-200": disabled },
          decorationClassName
        )}
      >
        {decoration}
      </div>
    </div>
  );
}

const CustomInputWrapper = forwardRef(
  (
    {
      name,
      value,
      label,
      onClick,
      disabled,
      inputClassName,
      icon = <BiCalendar size="1rem" />,
    },
    ref
  ) => (
    <div className="w-full" onClick={onClick} ref={ref}>
      <InputGroup
        name={name}
        value={value}
        label={label}
        decoration={icon}
        disabled={disabled}
        onChange={() => null}
        inputClassName={inputClassName}
      />
    </div>
  )
);

const InputDateTime = forwardRef(
  (
    {
      label,
      className,
      onInputChange,
      placeholder = "Select Date",
      dateFormat = "YYYY-MM-dd HH:mm:ss",
      value = new Date(),
      minDate = new Date(),
      disabled= false
    },
    ref
  ) => {
    const [date, setDate] = useState(value);

    useImperativeHandle(ref, () => ({
      onGetValue: () => {
        return date;
      },
    }));

    const onEventChange = (d) => {
      setDate(d);
      onInputChange?.(d);
    };

    return (
      <div ref={ref} className={cn("w-full", className?.container)}>
        {label && (
          <div className={cn("mb-1 text-sm text-gray-700", className?.label)}>
            {label}
          </div>
        )}

        <DatePicker
          name="Datepicker"
          selected={date}
          onChange={onEventChange}
          customInput={
            <CustomInputWrapper
              name={`datepicker-${label}`}
              label={placeholder}
            />
          }
          minDate={minDate}
          wrapperClassName={"w-full"}
          startDate={date}
          popperPlacement="bottom"
          showTimeSelect
          dateFormat={dateFormat}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default InputDateTime;
