import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { HiBuildingOffice } from "react-icons/hi2";
import { TbStairsUp } from "react-icons/tb";
import { BsDoorClosedFill } from "react-icons/bs";
import { useAppContext } from "../../context/AppContextProvider";
import { getAnalytics } from "../../services/api.service";
import CardCount from "../../components/CardCount";

const DashboardEmployee = () => {
  const navigate = useNavigate();
  const { userDetail } = useAppContext();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const isSuperAdmin = userDetail?.levelName === "superadmin";

  useEffect(() => {
    onGetAnalytics();
  }, []);

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics('module-facility');
      if (!response.success) throw response.message;

      setFetchLoading(false);

      console.log("response.data", response.data)

      setAnalytics(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  };

  if (isSuperAdmin) {
    return navigate("/home");
  }

  return (
    <div className="p-6">
      <div>
        <div className="flex gap-6">
          <CardCount
            title="Buldings"
            value={analytics?.buildingCount || 0}
            icon={<HiBuildingOffice />}
            isLoading={fetchLoading}
            className={{
              icon: "text-[#5B93FF] bg-[#5B93FF]/20",
            }}
          />
          <CardCount
            title="Floors"
            value={analytics?.floorCount || 0}
            icon={<TbStairsUp />}
            isLoading={fetchLoading}
            className={{
              icon: "text-[#FFD66B] bg-[#FFD66B]/20",
            }}
          />
          <CardCount
            title="Rooms"
            value={analytics?.roomCount || 0}
            icon={<BsDoorClosedFill />}
            isLoading={fetchLoading}
            className={{
              icon: "text-[#FF8F6B] bg-[#FF8F6B]/20",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardEmployee;
