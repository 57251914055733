import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaLock } from "react-icons/fa6";
import { useAppContext } from "../../context/AppContextProvider";
import { authLogin } from "../../services/api.service";
import { getLocalToken, getSSOToken, saveLocalToken, saveSSOToken } from "../../services/jwt.service";
import { userDetailCacheMap } from './../../utils/mapper';
import { periodLicenseValidator } from "../../utils/helper";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SliderComponent from "./../../components/Slider";
import AlertComponent from './../../components/Alert';
import SocialIcons from "./../../assets/json/social.json";
import LogoImg from "./../../assets/image/smart-office-logo-black.png";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userDetail, setUserDetail } = useAppContext();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { register, setValue, formState: { errors }, handleSubmit } = useForm();

  const roomID = searchParams.get('room');

  useEffect(() => {
    const ssoToken = getSSOToken();
    const localToken = getLocalToken();

    if (ssoToken && localToken) {
      if (userDetail?.levelName === "superadmin") {
        return navigate("/super-admin/dashboard");
      }
      
      if(userDetail?.levelName === "employee" && roomID) {
        return navigate(`/calendar?room=${roomID}`);
      }

      return navigate("/home");
    }

    if (userDetail?.levelName === "owner") {
      const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);
      if (rangeOfDay <= 0) {
        setAlertOption({
          type: "error",
          open: true,
          message: 'Your period license has ended. Please contact administrator to extend.'
        });
      }
    }
  }, [userDetail, navigate]);

  const onLoginClick = async (data) => {
    try {
      if (isLoading) return;

      setLoading(true);
      const response = await authLogin(data);
      if (!response?.success) throw response?.message;
      const user = userDetailCacheMap(response.data);

      setLoading(false);
      setUserDetail(user);
      saveLocalToken(response.data?.token);
      saveSSOToken(response.data?.sso_token);

      if (user.levelName === "superadmin") {
        return navigate('/dashboard');
      }

      if (user.levelName === "employee" && roomID) {
        return navigate(`/calendar?room=${roomID}`);
      }

      return navigate('/home');
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", open: true, message: error });
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const renderSocialIcon = () => {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/svg/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  return (
    <div className="h-screen lg:flex font-outfit">
      <div className="hidden h-screen lg:w-[380px] lg:bg-[#5557DB] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={LogoImg} className="w-52" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="relative lg:w-[calc(100%-380px)] select-none">
        <AlertComponent
          type={alertOption?.type}
          open={alertOption?.open}
          message={alertOption?.message}
          onClose={setAlertOption}
        />
        <div className="p-8">
          <Link to="/">
            <img src={LogoImg} alt="" className="w-24" />
          </Link>
        </div>
        <div className="h-[calc(100%-96.14px)] flex justify-center items-center">
          <form className="lg:w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onLoginClick)}>
            <div className="mb-6 text-center">
              <h3 className="text-[#0F1628] text-4xl">Login</h3>
              <span className="text-base">Selamat datang kembali di KantorPintar</span>
            </div>
            <div className="mb-5">
              <Input
                label="Email"
                icon={<FaEnvelope />}
                placeholder="Masukkan Email"
                errorMessage={errors?.email?.message}
                onInputChange={(e) => onChange('email', e.target.value)}
                {...register("email", {
                  required: "Email wajib diisi!",
                  pattern: { value: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, message: "Alamat email tidak sesuai!" }
                })}
              />
            </div>
            <div className="mb-3">
              <Input
                type={showPassword ? "text" : "password"}
                label="Password"
                icon={<FaLock />}
                placeholder="Masukkan Password"
                errorMessage={errors?.password?.message}
                showPassword={showPassword}
                onShowPassword={setShowPassword}
                onInputChange={(e) => onChange('password', e.target.value)}
                {...register("password", { required: "Password wajib diisi!" })}
              />
            </div>
            <div className="text-right text-[#4D4EC5] text-sm font-semibold">
              <Link to="/forgot-password">Lupa Password?</Link>
            </div>
            <div className="mt-6">
              <Button label="Login" isLoading={isLoading} className="bg-purple hover:bg-purple/75" />
            </div>
            <div className="mt-4 text-sm text-center">
              <span>Belum punya akun?</span>&nbsp;
              <Link to="/register" className="text-[#4D4EC5]">Daftar Sekarang</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;