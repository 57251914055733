import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser, FaArrowRightFromBracket, FaAngleDown } from "react-icons/fa6";
import { Transition } from "@headlessui/react";
import { useAppContext } from "../context/AppContextProvider";
import { cn, periodLicenseValidator } from "../utils/helper";
import Logout from "../pages/authenticate/Logout";
import ChangePassword from "../pages/authenticate/ChangePassword";

const Header = ({ children }) => {
  const dropdownRef = useRef(null);
  const { menuTitle, userDetail } = useAppContext();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const DROPDOWN = [
    {
      title: "Change Password",
      icon: <FaUser className="text-gray-300" />,
      onClick: () => setOpenChangePassword(true),
    },
    {
      title: "Logout",
      icon: <FaArrowRightFromBracket className="text-gray-300" />,
      onClick: () => setOpenLogout(true),
    },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  const onClickOutside = (e) => {
    if (dropdownRef.current?.contains(e.target)) return;
    setOpenDropdown(false);
  };

  const onClickDropdown = (onClick) => {
    setOpenDropdown(false);
    onClick();
  };

  const renderDropdown = () => {
    return DROPDOWN.map((item, index) => (
      <div
        key={index}
        className="flex items-center gap-2 p-2 rounded-lg cursor-pointer hover:bg-gray-100"
        onClick={() => onClickDropdown(item.onClick)}
      >
        {item.icon}
        <span>{item.title}</span>
      </div>
    ));
  };

  const renderInitials = () => {
    return userDetail?.name
      ?.split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  return (
    <div className="flex flex-col flex-auto bg-[#F0F5FF]">
      <nav className="z-10 flex items-center justify-between gap-4 py-2 bg-white px-8 max-h-20">
        <div className="text-base lg:text-lg font-bold">{menuTitle}</div>
        <div ref={dropdownRef} className="relative">
          <div
            className="flex items-center justify-end gap-4 cursor-pointer select-none border border[#E8E5E5] md:rounded-lg rounded-md pr-4"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div className="flex items-center justify-center text-white bg-[#232738] md:rounded-l-lg rounded-l-md md:w-12 md:h-12 w-7 h-7">
              {renderInitials()}
            </div>
            <div className="flex-1">
              <p className="text-sm font-semibold text-gray-800 capitalize">
                {userDetail?.name}
              </p>
            </div>
            <div className="transition-transform duration-300 ease-in-out">
              <FaAngleDown
                className={cn("transform", {
                  "rotate-180 duration-300": openDropdown,
                  "rotate-0 duration-300": !openDropdown,
                })}
              />
            </div>
          </div>
          <Transition
            as={Fragment}
            show={openDropdown}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute right-0 w-48 p-2 mt-2 text-base bg-white rounded-lg shadow-lg ring-1 ring-gray-300">
              {renderDropdown()}
            </div>
          </Transition>
        </div>
      </nav>
      <main className="flex-auto overflow-y-auto">{children}</main>
      <Logout open={openLogout} onOpen={setOpenLogout} />
      <ChangePassword
        open={openChangePassword}
        onOpen={setOpenChangePassword}
      />
    </div>
  );
};

export default Header;
