import { Fragment, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  FaUser,
  FaArrowRightFromBracket,
  FaAngleDown,
  FaXmark,
} from "react-icons/fa6";
import { BsExclamationTriangle } from "react-icons/bs";
import { Transition } from "@headlessui/react";

import { useAppContext } from "../context/AppContextProvider";
import { cn, periodLicenseValidator } from "../utils/helper";

import LogoImg from "../assets/image/smart-office-logo-black.png";

import Logout from "../pages/authenticate/Logout";
import ChangePassword from "../pages/authenticate/ChangePassword";

import AlertComponent from "../components/Alert";

const LayoutHome = () => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const { alertOption, onCloseAlert, userDetail } = useAppContext();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const [remainingTrialDays, setRemainingTrialDays] = useState(0);

  const DROPDOWN = [
    {
      title: "Change Password",
      icon: <FaUser className="text-gray-300" />,
      onClick: () => setOpenChangePassword(true),
    },
    {
      title: "Logout",
      icon: <FaArrowRightFromBracket className="text-gray-300" />,
      onClick: () => setOpenLogout(true),
    },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  const onClickOutside = (e) => {
    if (dropdownRef.current?.contains(e.target)) return;
    setOpenDropdown(false);
  };

  const onClickDropdown = (onClick) => {
    setOpenDropdown(false);
    onClick();
  };

  const renderDropdown = () => {
    return DROPDOWN.map((item, index) => (
      <div
        key={index}
        className="flex items-center gap-2 p-2 rounded-lg cursor-pointer hover:bg-gray-100"
        onClick={() => onClickDropdown(item.onClick)}
      >
        {item.icon}
        <span>{item.title}</span>
      </div>
    ));
  };

  const renderInitials = () => {
    return userDetail?.name
      ?.split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  useEffect(() => {
    // period license validation
    if (userDetail?.levelName !== "owner") return;

    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);
    if (rangeOfDay > 30) return;

    setRemainingTrialDays(rangeOfDay);
  }, [userDetail, setRemainingTrialDays]);

  return (
    <div id="layout" className="bg-[#F0F5FF] relative">
      {/* Navbar */}
      <nav className="z-10 flex items-center gap-4 py-2 bg-white max-h-20 md:px-28 px-8 absolute top-0 w-full">
        <div
          className="text-base lg:text-xl font-bold text-sky-700 cursor-pointer hover:text-sky-900 flex-1"
          onClick={() => navigate("/home")}
        >
          <img alt="" src={LogoImg} className="md:h-7 h-4" />
        </div>
        <div ref={dropdownRef} className="relative md:min-w-48">
          <div
            className="flex items-center justify-end gap-4 cursor-pointer select-none border border[#E8E5E5] md:rounded-lg rounded-md pr-4"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div className="flex items-center justify-center text-white bg-[#232738] md:rounded-l-lg rounded-l-md md:w-12 md:h-12 w-7 h-7">
              {renderInitials()}
            </div>
            <div className="flex-1">
              <p className="text-sm font-semibold text-gray-800 capitalize">
                {userDetail?.name}
              </p>
            </div>
            <div className="transition-transform duration-300 ease-in-out">
              <FaAngleDown
                className={cn("transform", {
                  "rotate-180 duration-300": openDropdown,
                  "rotate-0 duration-300": !openDropdown,
                })}
              />
            </div>
          </div>
          <Transition
            as={Fragment}
            show={openDropdown}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute right-0 w-48 p-2 mt-2 text-base bg-white rounded-lg shadow-lg ring-1 ring-gray-300">
              {renderDropdown()}
            </div>
          </Transition>
        </div>
      </nav>
      {/* End of Navbar */}
      <div className="pt-12 md:pt-16 pb-40 min-h-screen">
        <div className="md:px-28 md:py-20 px-8 py-10">
          {/* Trial Banner */}
          {remainingTrialDays > 0 && (
            <div
              className="bg-[#FFFBED] border border-[#F29705] text-[#232738] px-4 py-3 rounded mb-10 flex flex-col gap-3 relative"
              role="alert"
            >
              <div className="flex flex-row gap-2 items-center">
                <BsExclamationTriangle className="text-[#F29705]" />
                <strong className="font-bold">{`Your trial period ends in ${remainingTrialDays} days!`}</strong>
              </div>
              <p className="block sm:inline">
                Upgrade now to continue enjoying all Kantor Pintar premium
                features without interruption
              </p>
              <div className="flex justify-end">
                <a
                  className="py-2 px-4 bg-white rounded-md text-[#0978FE] border border-[#0978FE] max-w-24"
                  href="mailto:support@awandigital.id"
                >
                  Upgrade
                </a>
              </div>
              <span
                className="absolute top-0 bottom-0 right-0 cursor-pointer px-2 md:px-4 py-3 "
                onClick={() => setRemainingTrialDays(0)}
              >
                <FaXmark />
              </span>
            </div>
          )}
          {/* End of Trial Banner */}

          {/* Main */}
          <main>
            <Outlet />
          </main>
          {/* End of Main */}
        </div>
      </div>
      {/* Footer */}
      <footer className="flex flex-col flex-auto bg-[#232738] text-white max-h-44 gap-4 md:px-28 py-6 px-8 absolute bottom-0 w-full">
        <h1 className="font-medium text-xl">Need Help?</h1>
        <h2 className="font-normal text-base">
          Our Support Team is Always Ready to Help
        </h2>
        <div className="flex items-center justify-between">
          <a
            href="mailto:support@awandigital.id"
            className="font-semibold text-base underline"
          >
            Contact Support
          </a>
          <p className="font-normal text-xs">
            2024 Kantor Pintar. All rights reserved.
          </p>
        </div>
      </footer>
      {/* End of Footer */}
      {/* Popup */}
      <Logout open={openLogout} onOpen={setOpenLogout} />
      <ChangePassword
        open={openChangePassword}
        onOpen={setOpenChangePassword}
      />
      <AlertComponent
        type={alertOption?.type}
        open={alertOption?.open}
        message={alertOption?.message}
        onClose={onCloseAlert}
      />
      {/* End of Popup */}
    </div>
  );
};

export default LayoutHome;
