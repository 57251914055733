import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaUsersGear,
  FaChartSimple,
  FaBuilding,
  FaHouse,
  FaStairs,
} from "react-icons/fa6";
import { FiPlus, FiMinus } from "react-icons/fi";
import { MdArrowBackIos } from "react-icons/md";
import LogoImg from "./../assets/image/smart-office-logo-black.png";
import SmallLogoImg from "./../assets/image/smart-office-logo-black-small.png";
import { useAppContext } from "../context/AppContextProvider";
import { cn } from "../utils/helper";
import { HiOutlineUsers, HiOutlineBuildingOffice } from "react-icons/hi2";
import { RxDashboard } from "react-icons/rx";
import { TbStairsUp } from "react-icons/tb";
import { BsDoorClosed } from "react-icons/bs";


const DefaultMenus = [
  {
    title: "Dashboard",
    icon: <RxDashboard />,
    type: "menu",
    level: ["superadmin", "owner", "staff"],
    license: "standard",
    link: "/super-admin/dashboard",
    submenus: [],
  },
  {
    title: "Modul Owner",
    icon: <FaUsersGear />,
    type: "menu",
    level: ["superadmin"],
    license: "standard",
    link: "/super-admin/owner",
    submenus: [],
  },
];

const ModuleEmployeeMenus = [
  {
    title: "Dashboard",
    icon: <RxDashboard />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-employee",
    submenus: [
      // {
      //   label: "Example Sub-menus 1",
      //   url: "/example-1",
      // },
      // {
      //   label: "Example Sub-menus 2",
      //   url: "/example-2",
      // },
      // {
      //   label: "Example Sub-menus 3",
      //   url: "/example-3",
      // },
    ],
  },
  {
    title: "Employee",
    icon: <HiOutlineUsers />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-employee/employee",
    submenus: [],
  },
];

const ModuleFacilityMenus = [
  {
    title: "Dashboard",
    icon: <RxDashboard />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility",
    submenus: [],
  },
  {
    title: "Manage Building",
    icon: <HiOutlineBuildingOffice />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/buildings",
    submenus: [],
  },
  {
    title: "Manage Floor",
    icon: <TbStairsUp />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/floors",
    submenus: [],
  },
  {
    title: "Manage Room",
    icon: <BsDoorClosed />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/rooms",
    submenus: [],
  },
];

const ModuleMrmMenus = [
  {
    title: "Dashboard",
    icon: <RxDashboard />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-mrm",
    submenus: [],
  },
];

const mappingMenus = {
  default: DefaultMenus,
  employee: ModuleEmployeeMenus,
  facility: ModuleFacilityMenus,
  "meeting room management system": ModuleMrmMenus,
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail, setStore, activeModule, setKey } = useAppContext();
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const [showLabel, setShowLabel] = useState(true);

  useEffect(() => {
    const moduleMenus = mappingMenus[activeModule] || mappingMenus["default"];

    const menuTitle = moduleMenus.find(
      (menu) => location.pathname === menu.link
    )?.title;
    setStore({ menuTitle });
  }, []);

  const onClickMenu = ({ title, link, submenus }) => {
    setStore({ menuTitle: title });
    setActiveMenu(link);
    if (submenus?.length === 0) {
      navigate(link);
    } else {
      setShowLabel(true);
      setKey();
    }
  };

  const renderMenus = () => {
    const moduleMenus = mappingMenus[activeModule] || mappingMenus["default"];
    return moduleMenus.map((menu, index) => {
      if (!menu.level.includes(userDetail?.levelName)) return null;

      return (
        <li key={index}>
          <div
            className={cn(
              "flex items-center justify-between p-2 hover:bg-gradient-to-r hover:from-[#e6e6e9]/90 hover:to-40%  rounded cursor-pointer gap-2 min-h-10 text-[#030229]",
              {
                "bg-[#e6e6e9] font-bold hover:bg-[#e6e6e9]/50":
                  activeMenu == menu.link,
              }
            )}
            onClick={() => onClickMenu(menu)}
          >
            <div className="w-4 h-4 m-1">{menu.icon}</div>
            {showLabel && (
              <div className="flex items-center justify-between flex-1">
                <p className="text-base">{menu.title}</p>
                {menu.submenus.length > 0 && (
                  <div className="flex justify-center items-center relative w-6 h-6">
                    <FiMinus
                      className={`absolute transition-transform duration-700 ease-in-out w-3 ${
                        activeMenu == menu.link && menu.submenus.length > 0
                          ? "scale-100"
                          : "scale-0"
                      }`}
                    />
                    <FiPlus
                      className={`absolute transition-transform duration-700 ease-in-out w-3 ${
                        activeMenu !== menu.link && menu.submenus.length > 0
                          ? "scale-100"
                          : "scale-0"
                      }`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {/* Submenu */}
          {activeMenu == menu.link && menu.submenus.length > 0 && showLabel && (
            <ul className="ml-4 mt-2 space-y-1 relative">
              <span className="absolute left-0 top-0 h-[calc(100%_-24px)] w-0.5 bg-[#e6e6e9]"></span>
              {menu.submenus.map((subMenu) => (
                <li
                  className="flex items-center pl-3 cursor-pointer relative"
                  onClick={() =>
                    onClickMenu({ title: menu.title, link: subMenu.url })
                  }
                >
                  <span className="absolute -left-0 bottom-5 w-3 h-2 border-b-2 border-l border-[#e6e6e9] rounded-bl-md"></span>
                  <p className="flex-1 pl-3 py-2 hover:bg-[#e6e6e9]/50 hover:rounded-md text-base">
                    {subMenu.label}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    });
  };

  const defaultURL =
    userDetail?.levelName === "superadmin" ? "/dashboard" : "/home";
  return (
    <aside
      className={cn("min-w-60 h-screen bg-white p-4 relative", {
        "min-w-8": !showLabel,
      })}
    >
      <a
        href={defaultURL}
        className="flex justify-centerext-center no-underline mb-9"
      >
        <img alt="" src={showLabel ? LogoImg : SmallLogoImg} className="md:h-7 h-4" />
      </a>
      <ul className="space-y-2">{renderMenus()}</ul>
      <div
        className={cn(
          "absolute bottom-4 flex items-center justify-between p-2 hover:bg-[#e6e6e9]/50 rounded cursor-pointer gap-2 min-h-10 w-[90%]",
          {
            "w-[50%]": !showLabel,
          }
        )}
        onClick={() => {
          setShowLabel(!showLabel);
          setKey();
        }}
      >
        <div className="w-4 h-4 m-1">
          <MdArrowBackIos />
        </div>
        {showLabel && (
          <div className="flex items-center justify-between flex-1">
            <span className="text-base">Hide</span>
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
