import React from "react";
import { cn } from "../utils/helper";
import { AiOutlineLoading } from "react-icons/ai";

const CardCount = ({ title, value, icon, isLoading, className }) => {
  return (
    <div
      className={
        "w-1/4 flex gap-3 bg-white px-5 py-7 rounded-2xl justify-start"
      }
    >
      <div>
        <div
          className={cn(
            "text-xl font-bold p-5 text-gray-800  bg-gray-400/70 rounded-full",
            className?.icon
          )}
        >
          {icon}
        </div>
      </div>

      <div className="flex flex-col gap-0 justify-between">
        {isLoading && <AiOutlineLoading className="w-5 h-5 animate-spin" />}
        {!isLoading && <p className="text-2xl font-semibold">{value}</p>}
        <span className={"text-sm text-[#030229]"}>{title}</span>
      </div>
    </div>
  );
};

export default CardCount;
