import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from "./context/AppContextProvider";
import RoutesManager from './routes/RoutesManager';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <RoutesManager />
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;